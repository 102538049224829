// WordPresss theme details 
/*
Theme Name: Infinity Theme
Theme URI: http://www.infinitywebdesign.uk
Author: Kevin Walsh
Author URI: http://www.infinitywebdesign.uk
Description: Custom-designed theme for Infinity Web Design
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: infinity-theme
Tags: one-column, two-columns, right-sidebar, flexible-header, accessibility-ready, custom-colors, custom-header, custom-menu, custom-logo, editor-style, featured-images, footer-widgets, post-formats, rtl-language-support, sticky-post, theme-options, threaded-comments, translation-ready
*/

@import 'grid';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

$slategrey: #333333;
$lightest: #fcf6f6;
$lightgrey: #f5f1f1;
$midgrey: #dddbdb;
$darkgrey: #b3b3b3;
$white: white;

$infinityred : #10c500; // Playing around with colours
$infinityred: #eb19c8; // Playing around with colours
$infinityred: #C50008;
$infinityblue: #088DC5;
$infinitypurple: #403f7a;
$infinitygreen: #10c500;
$redlighter: lighten($infinityred, 60%);
$reddarker: darken($infinityred, 15%);
$bluelighter: lighten($infinityblue, 50%);
$bluedarker: darken($infinityblue, 15%);
$purplelighter: lighten($infinitypurple, 45%);
$purplelightest: lighten($infinitypurple, 60%);
$navcolour: $infinityblue;
$navcolour: $infinitygreen;
$navcolour: $infinitypurple;
$navcolour: $slategrey;
$navcolour: $infinityred;
$navcolour	: darken($infinitypurple, 15%);


// $navcolour: darken($navcolour, 10%);
$hovernav: lighten($navcolour, 10%);

$navheight: 4rem;
$radius: 15px;
$disgradient : linear-gradient(to bottom, rgba($slategrey, 0.8) 50%, rgba($slategrey, 0.999));

@mixin top-bottom-gradient($colour) {
    background: linear-gradient(to bottom, rgba($colour, 0.2) 25%, rgba($colour, 0.4) 50%, rgba($colour, 0.2));
}

// Footer height for sticky footer
$footerheight: 5rem; // Used for footer, but NOT for body bottom padding (which is $footwrapper)
$contactheight: 17rem;
$footwrapper: calc(#{$footerheight} + #{$contactheight});
// Now used to wrap #contact and footer (= total height of both)

// Breakpoints for media() mixin
$col: 576px;
$md : 768px;
$lg : 992px;
$lg2: 1150px;

// MIXINS - these need to come before any classes that include them!

@mixin media($breakpoint) {
    @if $breakpoint == col {
        @media screen and (max-width: $col) { @content}; 
    }
    @if $breakpoint == col-md {
        @media screen and (min-width: $col) and (max-width: $md) { @content}; 
    }
    @if $breakpoint == col-lg {
        @media screen and (min-width: $col) and (max-width: $lg) { @content}; 
    }
    @if $breakpoint ==  md {
        @media screen and (max-width: $md) { @content};
    }
    @if $breakpoint ==  min-md {
        @media screen and (min-width: $md) { @content};
    }
    @if $breakpoint ==  md-lg {
        @media screen and (min-width: $md) and (max-width: $lg) { @content};
    }
    @if $breakpoint == lg {
        @media screen and (max-width: $lg) { @content};
    }
    @if $breakpoint == lg2 {
        @media screen and (max-width: $lg2) { @content};
    }
    @if $breakpoint == lgmin {
        @media screen and (min-width: $lg) { @content};
    }
}

@mixin radshad {
    border-radius: 15px;
    box-shadow: 1rem 1rem 1rem $lightgrey;
}
@mixin radshaddark {
    border-radius: 5px;
    box-shadow: .5rem .5rem 1rem rgba(0, 0, 0, 0.2)
}
@mixin toprad {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
}
@mixin mlr {
    margin-left: auto;
    margin-right: auto;
}

@mixin whiteblue {
    background-color: $infinityblue;
    color: white;
}
@mixin whitepurple {
    background-color: $infinitypurple;
    color: white;
}
@mixin lighterblue {
    background-color: $bluelighter;
    color: initial;
}
@mixin ptb($rem) {
    padding-top: $rem + rem;
    padding-bottom: $rem +rem;
}
@mixin padding($rem) {
    padding: $rem +rem;
}
@mixin rad($px) {
    border-radius: $px +px;
}

@mixin mtb ($top, $bottom) {
    margin-top: $top + rem;
    margin-bottom: $bottom + rem
}

/* GLOBAL  */
*, 
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 1.5rem;
}
body {
    font-family: Ubuntu, Roboto, Arial, Helvetica, sans-serif;
    padding-top: $navheight;
    font-weight: 300;
    color: rgb(94, 91, 91) !important;
    
    // Setting up body for sticky footer
    position: relative; // Because the footwrapper is absolute
    min-height: calc(100vh - #{$footwrapper} - #{$navheight} );
    // NOTE: $footerheight/$navheight need to be interpolated in calc() using #{}.
    // min-height of body needs to be total less footer and nav
    // padding-bottom: $footwrapper; // So footer doesn't obscure content
    box-sizing: content-box;
    // This HAS to be content-box, so the min height + footer + nav = 100%
    // If it's border-box (the default) the total height is 100% less footer less header, so on short pages, it 
    // stops short of the end of the viewport. 
}

@include media(min-md) { // padding is needed only when .footwrapper is absolute i.e. >768px
    body {
        padding-bottom: $footwrapper;
        ;
    }
}

.container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}
.container-wide {
    @extend .container;
    max-width: 1600px;
}
.container-max {
    @extend .container;
    // width: 95%;
    max-width: unset !important;
}

@include media(lg) { // So the slideshow expands to 100%
    .container.slider {
        width: unset;
    }
}

/* JUMBOTRON */
@keyframes bannerFade {
    from {opacity: 0} to {opacity: 1}
}

@keyframes pulse {
    0% {
        color: yellow;
    }
    50% {
        color: rgb(255, 187, 0);
    }
    100% {
        color: yellow;
    }
}
@keyframes growShrink {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1)
    }
    100% {
        transform: scale(1)
    }
}
#jumbotron {
    // padding-top: var(--jumbotron-padding-y);
    // padding-bottom: var(--jumbotron-padding-y);
    margin-bottom: 0;
    background-color: #fff;
    background-image: url("img/infinity-sky.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // height: var(--jumbo-h);
    min-height: 500px;
    @include media (col) {
        min-height: 350px
    }
    display: flex;
    justify-content: center;
    align-items: center;
    & #jumbotext-outer {
        width: 500px;
        min-height: 250px; 
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgba(255,255, 255, 0.3);
        position: relative;
        border-radius: 10px;
        border: 1px solid lightgray;
        @include media (col){
            width: 90%;
            margin: 0 auto;
            height: 200px;
        }
    }
    & #jumbotext-inner, #jumbotext-inner2 {
        background-color: $bluedarker;
        // border: 1px solid orange;
        width: 400px;
        @include media(lg) {
            width: 90%;
        }
        min-height: 70px;
        @include media (md) {
            min-height: 60px;
        }
        & h2 {
            color: $white;
            font-size: 4rem;
            display: block;
            text-align: center;
            margin: 0;
            // border: 1px solid red;
            @include media(lg){
                font-size: 3rem;
            }
        }
    }
    & #jumbotext-inner2 {

        h2{
        color: yellow;
        padding-bottom: 1rem;
        }
    }
    & #inner2-text {
        animation: bannerFade 2s forwards;
    }
}

/* PORTFOLIO - on Home page and single page */

.portfolio  { // Overriding grid element setup for portfolio layout. Only odd items need RH padding.
    padding: 1rem !important;
    margin-bottom: 0 !important;
    position: relative;
    @include media(col) {
        padding: unset !important;
        padding-bottom: 1rem !important;
    }
    .inner {
        transition: filter 1s; // Uncomment to fade more slowly than it dips
    }
    &:hover .inner {
        filter: grayscale(100%);
    }
}
.overlay  {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 125px;
    background: rgba($infinitypurple, .3);
    background: none;
    transform: translate(-50%, -50%);
    @include rad(5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    transition: all .5s;
}


@keyframes glow {
    0% {
        background-color: $infinitypurple;
        background-color: $infinityblue;
    }
    
    50% {
        background-color: $infinityblue;
        background-color: $infinitygreen;
        background-color: $reddarker;
        background-color: $infinityred;
        background-color: $bluedarker;
    }
    
    100% {
        background-color: $infinitypurple;
        background-color: $infinityblue;
    }
}

.portbutton {
    position: relative;
    @include rad(5);
    padding: 0.5rem;
    background-color: $infinityblue;
    color: white;
    font-size: 80%;
    border: 1px solid $bluelighter;
    box-shadow: .3rem .3rem .75rem rgba(0, 0, 0, 0.5);
    animation: glow 3s infinite;
}


/* Portfolio single styles */

$circleheight: 75px; // Used for circle and padding
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 100%
    }
}

@keyframes dropDown {
    from {
         top: -250px
    }

    to {
        top: 0
    }
}
.portfolio-single { // container on single-portfolio.php
    font-size: 1.1rem;
    align-items: flex-start;
    & img {
        max-width: 100%;
        height: unset;
    }
    & h2 {
        background-color: $purplelightest;
        @extend .centred;
        color: white;
        color: $infinitypurple;
        /***
        
        NOTE: @extend puts CSS for .portfolio-single h2 entries after EACH of the classes it extends. So we get:
        .whitetext, .portfolio-single h2 {color: white; }
        .hovernav, .portfolio-single h2 {background-color: #52509c; }
        .centred, .portfolio-single h2 {text-align: center; }

        ***/
        // @include toprad();
        @include ptb(1);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    & .inner { // Overriding some inner styles just for the portfolio
        padding: 0;
        box-shadow: unset;
        border: none;
        animation: fadeIn .5s ease-in;
        &.web-graphic {
            box-shadow: .5px .5px 12px rgba(0, 0, 0, 0.2);
            overflow: hidden;

        }
    }
    & .single-content { // Descriptive panel 
        // @include rad(15);
        animation: fadeIn 1s ease-in;
        border: none;
        border-radius: none;
        // border: 1px solid $purplelighter;
        overflow: hidden;
    }
    & p {
        padding: .5rem 1rem;
    }

}



.innertest {
    color: $infinitypurple;
    position: relative;
    padding: 1.5rem;
    padding-bottom: .5rem;
    // border-top: solid .5px $purplelighter;
    position: relative;
    z-index: 2;
    // max-width: 400px;
    margin: 0 auto;
    background-color: $purplelightest;
    @media screen and (min-width: 476px) and (max-width: $lg2) {
        margin-bottom: 1rem;
    }
    h3 {
        padding: .5rem 1rem;
        margin-bottom: -0.5rem;
    }

    h4 {
        padding-left: 1rem;
        font-style: italic;
    }

    &::before,
    &::after {
        position: absolute;
        font-family: FontAwesome;
        font-size: 1.5rem;
        color: $infinitypurple;
        color: $purplelightest;
        color: $purplelighter;
        // animation: spin 1.5s linear infinite;
        // animation: jiggle 1s linear infinite;
    }
    /*
    &::before {
        content: "\f10d";
        top: 1rem;
        left: .3rem;
    }

    &::after {
        content: "\f10e";
        bottom: 2.5rem;
        right: .3rem;
    }
    */

}

@keyframes jiggle {
    0% {
        transform: translateY(0rem) rotate(0deg);
    }

    25% {
        transform: translateY(.1rem) rotate(-10deg);
    }

    50% {
        transform: translateY(.2rem) rotate(0deg);
    }

    75% {
        transform: translateY(.1rem) rotate(10deg);
    }

    100% {
        transform: translateY(0) rotate(0deg);
    }
}
.jiggle {
    animation: jiggle 3s infinite ease-in-out;
}

/* Other portfolio items */

/* .otherportfolio is under the wesbsite image on the left-hand side, and visible ONLY above 1150px. */
.otherportfolio {
    margin: 2rem 0;
    border: 1px solid #e7e5e5;
    background-color:#f5f5f5;
    padding: 0.5rem;
    border-radius: 15px;
    .otheritem img {
        border: 1px solid lightgray;
        border-radius: 15px;
        transition: all .5s;
        &:hover {
            transform: translateY(.5rem);
        }
    }
    h3 {
        color: gray;
        color: $infinitypurple;
    }
}

/* .mobileportfolio is under the sidebar, and visible ONLY up to 1150px */
.mobileportfolio {
    // display: none;
    @include media(lg2) {
        display: block;
    }
}

.homebutton {
    background-color: $infinitypurple;
    // background-color: $purplelighter;
    color: white; 
    outline: none;
    border: 1px solid white inset;
    padding: 0.5rem;
    border-radius: 5px;
    margin-left: .5rem;
    text-decoration: none !important;
    transition: all .5s;
    box-shadow: .25rem .25rem .5rem gray;
    &:hover {
        transform: translateY(.5rem);
    }
}


/* TYPOGRAPHY */
.lead {
    font-size: 120%;
    letter-spacing: .1rem;
    margin-bottom: 0.5rem;
    font-weight: 100;
}
.muted {
    color: $darkgrey;
}

.btn { // Back button on single-portfolio.php (& others?)
    background-color: $midgrey;
    @include padding(.5);
    @include rad(5);
    // margin: 0;
    outline: none;
    cursor: pointer;
    border: 1px solid $darkgrey;
    &:hover {
        background-color: $darkgrey;
        color: $slategrey;
    }
}


/* SCROLL REVEAL */

.hide {
    opacity: 0;
}

.reveal {
    animation: scrollShow .5s ease-in-out forwards;
}


@keyframes scrollShow-OLD {
    0% {
        transform: scale(.8);
        opacity: .25;
    }

    50% {
        opacity: .5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes scrollShow {
    from {opacity: 0;}
    to {opacity: 1;}
}


/* BRAND COLOURS, based on the infinity logo */

.whitetext {
    color: white;
}
.whitebg {
    background-color: #fff;
}

.infinityslate {
    background-color: $slategrey;
}

.infinityred {
    background-color: $infinityred;
}
.redlighter {
    background-color: $redlighter;
}

.infinityblue {
    background-color: $infinityblue;
}
.bluelighter {
    background-color: $bluelighter;
}

.darkgrey {
    color: $darkgrey;
}
.lightest {
    background-color: $lightest;
}
.bgdarkgrey {
    background-color: $darkgrey;
}
.bgmidgrey {
    background-color: $midgrey;
}
.bglightgrey {
    background-color: $lightgrey;
}
.hovernav {
    background-color: $hovernav;
}
@mixin orangered {
    border: 1px solid orangered;
}
.orangered-border {
    @include orangered();
}

$navpad: 1rem;

/* NAVIGATION & LOGO */

// Structure: nav --> container --> navwrap --> navlogo / logotype / ul#header-menu

nav {
    z-index: 99; // Ensuring the slider doesn't scroll OVER the nav
    height: $navheight; // Body has the same padding as this so there's no overlap
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $navcolour;
    // border-bottom: 1px solid darken($navcolour, 25%);
    // border-bottom: 1px solid lighten($navcolour, 25%);
    // background: $disgradient;
    // box-shadow: 0px 2px 10px rgba(0,0,0, 0.6);
    color: white;
    padding: $navpad;
    @include media(col) {
        padding-left: 0;
        padding-right: 0;
    }
    & img {
    height: 30px;
    margin-right: .75rem;
    padding-top: 0.2rem;
    @include media(col) {
        // height: 25px;
    }
    }
}

.navwrap {
    display: flex;
    // align-items: center;
}
.navlogo { // Pushing Infinity logo down so it's aligned with the text
    align-self: flex-end;
    // color: white;
}

ul#header-menu { 
    // We don't want to target nav ul, as that'll then affect Slicknav, which is appending to nav.
    list-style: none;
    margin-left: auto;
    // align-self: flex-end;
    // To push the menu right. NOTE: justify-self is ignored in Flexbox layouts (unlike align-self)
    & li {
        display: inline-block;
        cursor: pointer;
        // position: relative;
        & a {
            text-decoration: none;
            color: white;
            @include padding(.5);
            // border-radius: 5px;
            transition: all .5s;
            // white-space: nowrap;
            // width: 100%;
            display: block;
            &:hover {
                background-color: rgba(255,255,255,0.2); 
                // Adds a white/transparent overloy, so it lightens the colour of the hovered menu item
                // regardless of colour (which is inherited from parent).
            }
        }
        & ul { // Sumbmenus - hidden by default
            position: absolute;
            display: none;
            background-color: $infinityblue;
            & li {
                display: block;
            }
        }
        &:hover > ul { // Submenus displayed when hovering over over parent li
            display: block;
        }   
    }
}

.logotype {
    // @include orangered(); // For highlighting/testing purposes
    font-size: 2rem;
    // display: inline-block;
    font-weight: 200;
    @include media(col) {
        font-size: 1.8rem;
        // display:none;
    }
    & a, & a:hover {
        color: white;
        text-decoration: none;
    }
}

.logoname { // Infinity, in a heavier font
    font-weight: 500;
    // @include orangered();
}

@include media(col) {
    .webdesign { // Remove 'web design', so we get just 'infinity' (stops menu dropping on to new line)
        display: none;
    }
    .logoname {
        font-weight: 300;
    }
}

/* SLICKNAV MENU ON, MAIN MENU OFF */

.slicknav_brand { 
    display: flex; // For horizontal display of logo and 'infinity'
}

.slicknav_menu {
    display: none;
    margin: 0;
    background-color: $navcolour;
    font-size: 120%;
}
.slicknav_btn {
    background-color: darken($navcolour, 5%);
}
.slicknav_nav .slicknav_row:hover {
    background-color: lighten($navcolour, 15%);
    color: white;
}
.slicknav_nav a:hover {
    background-color: lighten($navcolour, 25%);
    color: white;
}

.slicknav_nav {
    font-size: 1em;
}

@include media(lg) { // Turning off desktop menu & turning on Slicknav mobile menu.
    nav {
        background-color: unset;
        padding: 0;
        & .container {
            display: none;
        }
    }
    .slicknav_menu {
        display: block;
    }
    body {
        padding-top: 60px // Adjusted to height of slicknav_menu
    }

}

/* UTILITY CLASSES*/
.mtb1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.mtb2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.mtb4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.ptb1 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.ptb2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.ptb4 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.upper-spaced {
    text-transform: uppercase;
    letter-spacing: 1.5rem;
    @include media(md) {
        letter-spacing: .5rem;
    }
}
.centred {
    text-align: center;
}
.noborder {
    border: none !important;
}
.noshadow {
    box-shadow: none !important;
}

.hidden {
    display: none !important;
}
.HIDDEN {
    display: none !important;
}

.flexcol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

/* 
 DEFINITION */

[class *="-wrapper"] {
    /* Medium/large wrappers. Their children expand to 100% at different breakpoints, causing columns to collapse (or appear to) */
    max-width: 1200px;
    display: flex;
    /* Without flex-wrap: wrap, columns WON'T expand to 100% at breakpoints */
    flex-wrap: wrap;
}

/* Very simple column setup. They're just containers, and the padding means the .inner divs  (see below) have what APPEARS to be a gutter between then. We don't even need to call them .col, as > div picks up the direct children of the wrapper. */

[class *="-wrapper"]>div {
    padding: .5rem;
}

.one-two {width: 50%;}
.one-three {width: 33.3333%;}
.two-three {width: 66.6666%;}
.one-four {width: 25%;}
.two-four {width: 50%;}
.three-four {width: 75%;}

/* The .inner div has the content. Gutter created by padding of parent. */

.inner {
    height: 100%;
    /* So it fits the height of its parent, which is a flex item */
    // border: 1px solid $darkgrey;
    padding: 1rem;
    @include radshaddark();

    &:not(.nocentre) {
        @include media(col) {
            text-align: center;
        }
    }
    
    position: relative;
}

/* Wrappers' direct children collapse at different breakpoints */

@mixin collapse {
    width: 100%;
}

@include media(lg2) { // So we can collapse at 1200px for single portfolio items
    .xl-wrapper>div {
        width: 100%;
    }
}

@include media(lg) {
    .l-wrapper>div {
       width: 100%;
        &.four-two { // Special class to allow 4 columns to collapse to 2 at 992px before going full-width at 767px
            @include media(min-md) {
                width: 50%;
            }
        }
    }
    #showcase .inner {
        width: 100%;
        @include mlr();
    }
}

@include media(md) {
    .m-wrapper>div {
       width: 100%;
    }
    .testimonial {
        width: 75%;
        @include mlr()
    }
}

@include media(col) {
    .s-wrapper>div {
       width: 100%;
    }
    .testimonial {
        width: 95%;
        @include mlr()
    }
}

/* MEET KEVIN */
#meet-kevin {
    margin-top: 2rem;
    @include media(col) {
        margin-top: 0;
    }
    & p {
        font-size: 1.25rem;
        margin-bottom: 0.75rem;
    }
    & li {
        font-size: 1.25rem;
        margin-bottom: .5rem;
        &:last-child {
            margin-bottom: 1.5rem;
        }
        margin-left: 2rem;
        max-width: 700px;
    }
    & h2 {
        margin-bottom: 1rem;
        font-size: 2rem;
        color: $infinitypurple;
        @include media(col) {
            font-size: 1.75rem;
        }
    }
    & a {
        text-decoration: none;
        animation: glow 1s infinite;
        color: white;
        padding-left: .5rem;
        padding-right: 0.5rem;
        border-radius: 5px;
    }
    .inner {
        padding: 0;
    }
    .container {
        @include media(col) {
            width: 95% !important;
        }
    }
}

.kevinpic {
    max-width: 100%;
    display: block;
    margin-top: 5rem;
    border-radius: 10px;
    box-shadow: 1px 1px 10px gray;
    @include media(lg) {
        margin: 0 auto;
    }
    @include media(col) {
        max-width: 150px;
    }
}

/* Hack to make Kevin float between 768px and 992px */
@include media(md-lg) {
    #meet-kevin .l-wrapper  {
        display: block !important; // Resetting from flex...
        .one-four { // ... so we can float the image to the left
            float: left;
            width: unset;
            .kevinpic {
                max-width: 200px;
                margin-bottom: 0;
                margin-right: 1rem;
            }
            &::after {
                clear: both; 
                display: block;
            }
        }
        .three-four {
            width: unset;
        }
    }
}


/* SHOWCASE */
#showcase {
    & img {
    max-width: 100%;
    // border: 1px solid $darkgrey;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    box-shadow: 1px 1px 5px $darkgrey;
    height: unset !important; // overriding WordPress's height settings
    &:hover {
        transform: translateY(5px);
        // opacity: .5;
        // transform: scale(.95);
    }
    }
    & .inner {
        padding: 0;
        border: unset;
        box-shadow: unset;
    }
    & .l-wrapper {
        justify-content: space-around;
    }

    h1 {
        cursor: pointer;
    }
}

/* TESTIMONIALS */

.testimg {
    border-radius: 50%;
    max-width: 8rem;
    display: block;
    margin: 0 auto;
    filter:  grayscale(100%);
    margin-bottom: 1rem;
}
.testimonial {
    position: relative;
    background-color: $bluelighter;
    text-align: center;

    & h2 {
        margin-bottom: 1rem;
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
        clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%);
    }
    & h3 {
     display: inline-block;
     padding-left: 1rem;
     padding-right: 1rem;
     margin-top: .5rem;
    font-weight: normal;
    font-style: italic;
    }
    & p {
    text-align: center;
    margin-bottom: 1rem;
    }
}

#testimonials a { // Now that testimonials are hyperlinked, removing underlinke and color
    text-decoration: none;
    color: initial;
}

/* CONTACT */
#contact {
    // height: 300px;
    & i {
        font-size: 3rem;
        margin-bottom: 1rem;
    }
    & a {
        text-decoration: none;
        color: blue;
        color: white;
        transition: all .3s;
        &:hover{
            color: $infinityred;
            color: $infinityblue;
        }
    }
    .flexcol  {
        // border: 1px solid orangered; // Just for testing purposes
        align-items: center;
        & a {
            // border: 1px solid yellow; // Just for testing purposes
            margin-bottom: 1rem;
        }
    }
}


/* FOOTER */
footer {
    background-color: $darkgrey;
    background-color: $slategrey;
    background-color: $navcolour;
    color: white;
    padding: 2rem;
    text-align: center;
    margin: 0;
    height: $footerheight;
    span {
        @include media(md) {
            display: block;
        }
    }
}
@include media(min-md) {
    .footwrapper { /* Wraps #contact and footer in  footer.php */
    // Setting up sticky footer before collapse. On short pages, it's at the bottom of the screen.
    // On longer pages, it's at the bottom of the page. See <body> for the setup to accomplish this.
    // Only required above 768px. 
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: $footwrapper;
    }
}

/* SMART SLIDER CAROUSEL  */

// Bullets on Carousel
.n2-style-09efebcef1f2f45d29438e0cabcf79bc-dot {
    background: $darkgrey !important;
}
.n2-active {
    background: $infinityblue !important;
}

.n2-ss-spinner-simple-white-container { // Turning off the Smart Slider spinner, as Infinity has its own
    display: none;
}

// Banner headlines on each slide
.sliderbanner {
    top: 12rem;
    margin-right: .5rem !important;

    @media screen and (max-width: 1100px) {
        width: 50%;
        top: unset;
        color: red;
        // background-color: orangered;
        margin-right: unset !important;
    }

    & h1,
    h2 {
        background-color: rgba($infinitypurple, 0.75) !important;
        color: white !important;
        margin: 0 auto;

        @media screen and (max-width: 768px) {
            font-size: 1.5rem !important;
        }
    }
}


// Testing WordPress body styles
/* body:not(.logged-in) {
    background-color: #ffff9e;
}

.logged-in {
    background-color: #c1fac1;
}
.home {
    background-color: red;
} */


// windowMessage
/*
For displaying Window size message. displaySize() in utilities.js looks for #windowMessage and if it exists,
displays the message.
*/
#windowMessage { // Copied from base.css
    background-color: rgba($slategrey, 0.7);
    background-color: rgba($navcolour, 0.7);
    color: white;
    padding: 0.3rem;
    border-radius: 5px;
    margin: 0;
    position: fixed;
    top: 5rem;
    left: 10%;
    z-index: 100;
    display: none;
    // If we don't specify display:none, then it appears briefly as a dot (because of the padding), then either
    // expands to fit the content, or disappears if it's hidden. So it's best to start hidden
    // then make it visible if and only if necesary. That way, we get no brief appearance.
    &.show {
        display: block;
    }
}

/* SPIN Easter egg! */

@keyframes spinY {
    from {transform: rotateY(360deg)} to {transform: rotateY(0deg)}
}

@keyframes spin {
    from {transform: rotate(0deg)} to {transform: rotate(360deg)}
}
.spin {
    animation: spin 1s linear infinite;
}


/*
WINDOW MESSAGE
For displaying Window size message. displaySize() in utilities.js looks for #windowMessage and if it exists,
displays the message.
*/
#windowMessage {
    background-color: #8d8c8c;
    color: white;
    display: inline-block;
    padding: 0.3rem;
    border-radius: 5px;
    margin: 0;
    position: fixed;
    top: 5rem;
    left: 10%;
    z-index: 100;
    display: none;
    /* If we don't specify display:none, then it appears briefly as a dot (because of the padding), then either
    expands to fit the content, or disappears if it's hidden. So it's best to start hidden
    then make it visible if and only if necessary. That way, we get no brief appearance. */
}

#windowMessage.show {
    display: block;
    /* We need the ID here. Otherwise, the class is outranked by #windowMessage above. */
}

#windowSize {
    font-weight: bold;
}



/* TESTING CONTACT FORM 7 */
.wpcf7 {
    // background-color: orangered;
}
.responseform {
    background-color: yellow;
    @include media(lgmin) {
        width: 50%;
    }
    padding: 1.5rem;
    border: 1px solid black;
    border-radius: 15px;
    label {
        display: block;
        font-size: 1rem;
        margin-top: .5rem;
    }
    input {
        border-radius: 5px;
        min-height: 2rem;
        box-shadow: none;
        outline: none;
    }
    textarea {
        width: 100%;
    }
    select {
        margin-bottom: 2rem;
    }
    input[type="submit"] {
        @extend .portbutton;
        min-width: 5rem;
        font-size: 1.1rem;
    }
}

/* TESTING */


.jumbo-alternative {
    padding: Min(5vw, 4rem) 2rem;
    background-image: linear-gradient(rgba($infinitypurple, 0.9), rgba($infinitypurple, 0.9)), url(img/laptop.jpg);
    background-position: center;
    background-size: cover;
    color: whitesmoke;
    // height: Max(50vh, max-content);
    @media(max-width: 400px) {padding: 2rem 0}
    .container {
        height: 100%;
    }
    p {
        opacity: 0;
        letter-spacing: .5px;
    }
    .lead {
        font-size: Min(7vw, 2.2rem);
        font-weight: lighter;
        line-height: 1;
        height: 100%;
    }
    .call{
        font-weight: bold;
        margin: Min(15vh, 5rem) 0;
    }
    p.contact {
        font-size: Min(5vw, 1.4rem);
        margin-top: .65rem;
        letter-spacing: .5px;
    i {
            margin-right: .5rem;
        }
    }
    a {
        text-decoration: none;
        color: inherit !important;
    }
}
#jumbotron {
    display: none;
}

@keyframes slide {
    to {opacity: 1;}
}
.slide {
    animation: slide 2s ease-in forwards;
}

@for $num from 1 through 5 {
    .lead>*:nth-child(#{$num}) {
    //   animation: slide 1.5s calc(#{$num/1.75}s) ease forwards; // So we get 0s, 0.5s, 1s
      animation: slide 1.5s calc($num / 1.5)+s ease forwards; // So we get 0s, 0.5s, 1s
    }
  }

 