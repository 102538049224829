/* GRID styles */
.grid-test-style {
    display: grid; 
    grid-template-columns: repeat(2, 1fr) 2fr;
    color: white;
    background-color: red;
}

.another-grid {
    background-color: white;
    color: cyan;
    letter-spacing: 1rem;
}

.griddy {
    color: orange;
}

%extend-test {
    color: white;
}

.extend {
    @extend %extend-test;
    background-color: red;
}

.extra-extend {
    @extend .extend;
    background-color: gray;
    border: 1px solid white;
}

.yet-another {
    background-color: rgb(random(255), random(255), random(255));
}

.filtergrid {
    background-color: rgba(black, 0.5);
}
